import React from "react";
import "./whyUs.css";
import { Helmet } from "react-helmet";

export default function WhyUs() {
  return (
    <div>
      <Helmet>
  <meta charset="UTF-8" />
  <link rel="icon" href="./img/chain dp.jpg" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <meta name="theme-color" content="#000000" />
  <meta name="description" content="Chain Magician Lab - Your one-stop solution for all software requirements, including blockchain development, mobile applications, custom software solutions, decentralized applications, Ethereum development, smart contracts, secure and scalable software solutions." />
  <title>Chain Magician Lab | Blockchain, Mobile & Custom Software Development</title>
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.chainmagicianlab.com/" />
  <meta property="og:title:alt" content="Chain Magician Lab | Blockchain, Mobile & Custom Software Development" />
  <meta property="og:description" content="Chain Magician Lab offers high-quality blockchain development, mobile app creation, and custom software solutions to meet your business needs." />
  <meta property="og:image" content="https://www.chainmagicianlab.com/img/chain dp.jpg" />
  <meta property="twitter:card" content="summary_large_image" />
  <meta property="twitter:url" content="https://www.chainmagicianlab.com/" />
  <meta property="twitter:title" content="Chain Magician Lab | Blockchain, Mobile & Custom Software Development" />
  <meta property="twitter:description" content="Chain Magician Lab provides comprehensive software solutions including blockchain development, mobile apps, custom software, decentralized applications, and smart contracts." />
  <meta property="twitter:image:alt" content="https://www.chainmagicianlab.com/img/chain dp.jpg" />
  <link rel="canonical" href="https://www.chainmagicianlab.com/" />
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Chain Magician Lab",
        "url": "https://www.chainmagicianlab.com",
        "logo": "https://www.chainmagicianlab.com/img/chain dp.jpg",
        "sameAs": [
         "https://www.facebook.com/chainmagicianlab",
              "https://www.twitter.com/chainmagicianlab",
              "https://www.linkedin.com/company/103792856",
              "https://wa.me/8470847525",
              "https://www.instagram.com/chainmagicianlab/",
              "https://t.me/Chain_Magician_Lab"
        ],
        "description": "Chain Magician Lab offers high-quality blockchain development, mobile app creation, and custom software solutions to meet your business needs."
      }
    `}
  </script>
</Helmet>
      <div className="setbg_vd py-5">
        {/* <h1 className="text-center" style={{ color: "#b6eb00" }}>
          WHY DECRYPTCODERS
        </h1> */}
        <div className="section-header text-center">
        <h2 className="fw-bold fs-1">
          <span style={{color:"white"}}> WHY</span>
          <span className="b-class-secondary" style={{ color: "#26bd19" }}> Chain Magician  </span>
        </h2>
        <h2 className="sec-icon"><i className="fa-solid fa-gear"></i></h2>
      </div>
        <div className="container">
          <div className="row my-4 center-items">
            <div className="col-sm p-5">
              <h2 className="fs-5 pt-5 fw-bold">Experience and Expertise</h2>
              <ul>
                <li>
                  Years of experience in delivering custom software solutions
                  across diverse industries.
                </li>
                <li>
                  Skilled team of developers proficient in the latest
                  technologies and methodologies.
                </li>
              </ul>
            </div>
            <div className="col-sm p-5">
              <video width="100%" autoPlay loop>
                <source
                  src="./video/1109695_Animation_Dark-background_Animation_1280x720.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="row mt-4 center-items">
            <div className="col-sm center-items">
              <img height="80%" src="./img/7176408_3572684.svg" alt="" />
            </div>

            <div className="col-sm">
              <h2 className="fs-5 p-5 fw-bold">Client-Centric Approach</h2>
              <ul>
                <li>
                  Prioritizing client satisfaction by understanding their unique
                  requirements and goals
                </li>
                <li>
                  Collaborative development process ensuring transparency,
                  communication, and flexibility
                </li>
              </ul>
            </div>
          </div>

          <div className="row my-4 center-items">
            <div className="col-sm order-sm-1">
              <h2 className="fs-5 p-5 fw-bold">Timely Delivery</h2>
              <ul>
                <li>
                  Commitment to delivering projects on time, within budget, and
                  according to specifications
                </li>
                <li>
                  Efficient project management techniques to streamline
                  development and minimize delays.
                </li>
              </ul>
            </div>
            <div className="col-sm order-sm-2 center-items">
              <img src="./img/5205022_Delivery.svg" height={"80%"} alt="" />
            </div>
          </div>

          <div className="row my-4 center-items">
     
            <div className="col-sm order-sm-2">
              <h2 className="fs-5 p-5 fw-bold">Innovation and Adaptability</h2>
              <ul>
                <li>
                  Embracing innovation and staying updated with emerging
                  technologies to provide cutting-edge solutions.
                </li>
                <li>
                  Agile development methodology enabling rapid prototyping and
                  iterative improvements
                </li>
              </ul>
            </div>
            <div className="col-sm  order-sm-1 p-5 center-items">
              <img height={"400px"} src="./img/inn.webp" alt="" />
            </div> 
          </div>

          <div className="row my-4 center-items">
            <div className="col-sm p-5">
              <h2 className="fs-5 fw-bold">Support and Maintenance</h2>
              <ul>
                <li>
                  Dedicated support team available round-the-clock to address
                  any issues or concerns.
                </li>
                <li>
                  Proactive maintenance services to keep your software running
                  smoothly and securely.
                </li>
              </ul>
            </div>
            <div className="col-sm center-items">
              <img height={"80%"} src="./img/8821548_4018854.svg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="middle text-center my-5">
        <h1 className="my-5">Contact Us</h1>
        <a
          className="btnnn"
          target="_blank"
          href="https://wa.me/8470847525?text=Hello Chain Magician Lab  This  Message is Regarding Software development "
        >
          <i className="fab fa-whatsapp"></i>
        </a>

        <a className="btnnn" target="_blank" href="https://www.linkedin.com/in/anmolsingh-chainmagicianlab">
  <i className="fab fa-linkedin"></i>
</a>

        {/* <a
          className="btnnn"
          target="_blank"
          href="mailto:chainmagicianlab@gmail.com?subject=Enquiry for custom software development&body=Hello Chain Magician Lab, I want to enquire that"
        > */}
        <a className="btnnn" href="tel:+918470847525">
          <i className="fa fa-phone"></i>
        </a>

        <a className="btnnn"
        target="_blank" href="https://www.instagram.com/chainmagicianlab">
          <i className="fab fa-instagram"></i>
        </a>

        <a className="btnnn"
        target="_blank" href="https://t.me/Chain_Magician_Lab">
          <i className="fab fa-telegram-plane"></i>
        </a>
        <a
          className="btnnn"
          target="_blank"
          href="mailto:chainmagicianlab@gmail.com?subject=Enquiry for custom software development&body=Hello Chain Magician Lab, I want to enquire that"
        >
          <i className="fas fa-envelope"></i>
        </a>
      </div>

      <div className="text-center mb-2 text-dark">
         Chain Magician Lab Private Limited All Rights Reserved
      </div>
    </div>
  );
}
