import React from "react";
import "./workOn.css";
import { Helmet } from "react-helmet";

export default function WorkOn() {
  return (
    
    <div className="">

<Helmet>
  <meta charset="UTF-8" />
  <link rel="icon" href="./img/chain dp.jpg" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <meta name="theme-color" content="#000000" />
  <meta name="description" content="Chain Magician Lab - Your one-stop solution for all software requirements, including blockchain development, mobile applications, custom software solutions, decentralized applications, Ethereum development, smart contracts, secure and scalable software solutions." />
  <title>Chain Magician Lab | Blockchain, Mobile & Custom Software Development</title>
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.chainmagicianlab.com/" />
  <meta property="og:title:alt" content="Chain Magician Lab | Blockchain, Mobile & Custom Software Development" />
  <meta property="og:description" content="Chain Magician Lab offers high-quality blockchain development, mobile app creation, and custom software solutions to meet your business needs." />
  <meta property="og:image" content="https://www.chainmagicianlab.com/img/chain dp.jpg" />
  <meta property="twitter:card" content="summary_large_image" />
  <meta property="twitter:url" content="https://www.chainmagicianlab.com/" />
  <meta property="twitter:title" content="Chain Magician Lab | Blockchain, Mobile & Custom Software Development" />
  <meta property="twitter:description" content="Chain Magician Lab provides comprehensive software solutions including blockchain development, mobile apps, custom software, decentralized applications, and smart contracts." />
  <meta property="twitter:image:alt" content="https://www.chainmagicianlab.com/img/chain dp.jpg" />
  <link rel="canonical" href="https://www.chainmagicianlab.com/" />
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Chain Magician Lab",
        "url": "https://www.chainmagicianlab.com",
        "logo": "https://www.chainmagicianlab.com/img/chain dp.jpg",
        "sameAs": [
         "https://www.facebook.com/chainmagicianlab",
              "https://www.twitter.com/chainmagicianlab",
              "https://www.linkedin.com/company/103792856",
              "https://wa.me/8470847525",
              "https://www.instagram.com/chainmagicianlab/",
              "https://t.me/Chain_Magician_Lab"
        ],
        "description": "Chain Magician Lab offers high-quality blockchain development, mobile app creation, and custom software solutions to meet your business needs."
      }
    `}
  </script>
</Helmet>
      <section class="client setcard-background setBG-card ">
        <div class="container  mx-auto text-center">
          <h1 className="setH">Expertise Display</h1>

          <div data-aos="fade-up-right" className="row  mt-5">
            <div  className="col-sm">
              <div  class="card  setExpertCard  shadow"  >
                 <div class=" card-body">
                  <h6 className="fs-5">SMART CONTRACT</h6>
                  <div data-aos="fade-up-right" className="row ">
                    <div className="col-sm text-start  mt-3">
                      Over{" "}
                      <b className="text-warning" style={{ fontSize: "20px" }}>
                        550
                      </b>{" "}
                      smart contracts deployed and counting – paving the way innovation in  Blockchain Technologies.
                    </div>
                    <div className="col-sm text-center  ">
                      <img
                        src="./img/Animation - 1714160338354.gif"
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div class="card setExpertCard  shadow-lg" >
                <div class="card-body">
                  <h6 className="fs-5">ETH RPC & VALIDATOR NODE SETUPED</h6>
                  <div data-aos="fade-up-right" className="row ">
                    <div className="col-sm text-start  mt-3">
                      <p>
                        Setuped{" "}
                        <b className="text-warning" style={{ fontSize: "20px" }}>
                          9+
                        </b>{" "}
                        Blockchain enabling seamless interaction with the Ethereum blockchain...  
                      </p>
                    </div>
                    <div className="col-sm text-center  ">
                      <img
                        src="./img/Animation - 1714158417783.gif"
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div class="card setExpertCard  shadow-lg" >
                <div class="card-body">
                  <h6 className="fs-5">CROSS-CHAIN PLATEFORM</h6>
                  <div data-aos="fade-up-right" className="row ">
                    <div className="col-sm text-start  mt-3">
                      <p>
                        Established <b class="text-warning">13+</b> cross-chain
                        connections unlocking new possibilities
                        for decentralized
                      </p>
                    </div>
                    <div className="col-sm text-center  ">
                      <img
                        src="./img/Animation - 1714159442239.gif"
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div data-aos="fade-up-right" className="row  mt-5">
            <div className="col-sm">
              <div class="card setExpertCard  shadow"  >
                 <div class="card-body">
                  <h6 className="fs-5">WALLET DEVELOPED</h6>
                  <div data-aos="fade-up-right" className="row ">
                    <div className="col-sm text-start  mt-3">
                    <p>Created <b className="text-warning" style={{ fontSize: "20px" }}>3</b> wallets, providing users with secure access to assets moving torward  blockchain technology....</p>


                    </div>
                    <div className="col-sm text-center  ">
                      <img
                        src="./img/Animation - 1714164352081.gif"
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div class="card setExpertCard  shadow-lg" >
                <div class="card-body">
                  <h6 className="fs-5">Auditing</h6>
                  <div data-aos="fade-up-right" className="row ">
                    <div className="col-sm text-start  mt-3">
                      <p>
                         Smart-contract & Blockchain audits, ensuring
                        the security and integrity blockchain..... 
                      </p>
                    </div>
                    <div className="col-sm text-center  ">
                      <img
                        src="./img/Animation - 1714199891999.gif"
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div class="card setExpertCard  shadow-lg" >
                <div class="card-body">
                  <h6 className="fs-5">DATA MIGRATION</h6>
                  <div data-aos="fade-up-right" className="row ">
                    <div className="col-sm text-start  mt-3">
                      migrated data{" "}
                      <b className="text-warning" style={{ fontSize: "20px" }}>
                        21+
                      </b>{" "}
                       ensuring seamless transition and
                      continuity in blockchain operations...
                    </div>
                    <div className="col-sm text-center  ">
                      <img
                        src="./img/Animation - 1714221256745.gif"
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div data-aos="fade-up-right" className="row  mt-5">
            <div className="col-sm">
              <div class="card setExpertCard  shadow"  >
                 <div class="card-body">
                  <h6 className="fs-5">INTERNET OF THINGS</h6>
                  <div data-aos="fade-up-right" className="row ">
                    <div className="col-sm text-start  mt-3">
                      Over{" "}
                      <b className="text-warning" style={{ fontSize: "20px" }}>
                        10+
                      </b>{" "}
                      IoT devices deployed revolutionizing connectivity and
                      data-driven insights in the world
                    </div>
                    <div className="col-sm text-center  ">
                      <img
                        src="./img/Animation - 1714223375639.gif"
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div class="card setExpertCard  shadow-lg" >
                <div class="card-body">
                  <h6 className="fs-5">MOBILE APPLICATION</h6>
                  <div data-aos="fade-up-right" className="row ">
                    <div className="col-sm text-start  mt-3">
                      Over{" "}
                      <b className="text-warning" style={{ fontSize: "15px" }}>
                        50+
                      </b>{" "}
                      empowering users with innovative solutions and seamless
                      experiences 
                    </div>
                    <div className="col-sm text-center  ">
                      <img
                        src="./img/Animation - 1714223768546.gif"
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div class="card setExpertCard  shadow-lg" >
                <div class="card-body">
                  <h6 className="fs-5">MAJOR PLATEFORM </h6>
                  <div data-aos="fade-up-right" className="row ">
                    <div className="col-sm text-start  mt-3">
                     <div className=""></div>
                     <div>Exchanges : <b className="text-warning fs-5"> 2+</b></div> 
                     <div>NFT MarketPlace : <b className="text-warning fs-5"> 3+</b></div> 
                     <div>Social Apps <b className="text-warning fs-5"> 5+</b></div> 
                     <div>Ecommerce_webApp : <b className="text-warning fs-5"> 5+</b></div> 
                     {/* <div>Exchanges : <b className="text-success fs-5"> 2</b></div>  */}
                    </div>
                    <div className="col-sm text-center  ">
                      <img
                        src="./img/Animation - 1714159442239.gif"
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* blockchain crousal  */}
          {/* <div class="row table-responsive">
            <div class="carousel-client container d-flex">
              <div class="slide">
                <img className="setImg" src="./img/rth.png" />
              </div>
              <div class="slide">
                <img className="setImg" src="./img/sol.png" />
              </div>
              <div class="slide">
                <img className="setImg" src="./img/bnb.jpg" />
              </div>
              <div class="slide">
                <img className="setImg" src="./img/polygon.png" />
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <div className="setbody ">
        <button className="mx-2">Get Quotation</button>
        <button>Get Exact Pricing</button>
      </div>

      <div className="text-center mx-auto bgIMH ">
        <h1 className="my-5 text"> Conected World Connected Experience</h1>
        <div data-aos="fade-up-right" className="row mx-auto container">
          <div className="col-sm">
            <div class="card setCard">
              <div class="card-body">
                <h1 class="card-title text">17+ </h1>

                <p class="card-text text-sub">Connected To countries</p>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div class="card setCard">
              <div class="card-body">
                <h1 class="card-title text">1100+ </h1>

                <p class="card-text text-sub">Connected To Client</p>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div class="card setCard">
              <div class="card-body">
                <h1 class="card-title text">970+</h1>

                <p class="card-text text-sub">Project Deliverd</p>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up-right" className="row mx-auto my-5 container">
          <div className="col-sm">
            <div class="card setCard">
              <div class="card-body">
                <h1 class="card-title text">15+ </h1>

                <p class="card-text text-sub">Technologies we Work</p>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div class="card setCard">
              <div class="card-body">
                <h1 class="card-title text">25+</h1>

                <p class="card-text text-sub">Average CTA (Call to Action)</p>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div class="card setCard">
              <div class="card-body">
                <h1 class="card-title text">5+</h1>

                <p class="card-text text-sub">Exploring new avenues</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
